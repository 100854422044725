import getAdminApiPath from "@hornet-api/getAdminApiPath";
import axios from "axios";
import {FeedbackDetail, SubmitFeedbackServer} from "@interfaces/feedback/Feedback";
import getUserApiPath from "@hornet-api/getUserApiPath";

export const getFeedbackById = async (feedbackId: number) => {
  const url = `${getAdminApiPath()}/feedback/${feedbackId}`;
  return await axios.get<FeedbackDetail>(url).then(({data}) => data);
};

export const saveUserFeedback = async (data: SubmitFeedbackServer) => {
  const url = `${getUserApiPath()}/feedback`;
  return await axios.post<FeedbackDetail>(url, data)
    .then(({data}) => data);
};
