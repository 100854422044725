import axios from 'axios';
import {CreateTaskFormData} from "@admin-ui/pages/LoanShowPage/components/AddTaskToLoanModal";
import convertJsonToFormData from "@common/utils/form/convertJsonToFormData";
import getAdminApiPath from "@hornet-api/getAdminApiPath";


export const createUpdateTaskWithAttachment = async (data: CreateTaskFormData, taskId?: number) => {
  const {owner, loanId, ...createTaskData} = data;
  const loanIdStr = typeof loanId === 'string' ? loanId : loanId?.value
  const [ownerType, ownerId] = (typeof owner === 'string' ? owner : owner?.value)?.split('-') || [];

  const formData = convertJsonToFormData({
    ...createTaskData,
    ownerId,
    ownerType,
    loanId: loanIdStr
  });

  const url = taskId ? `${getAdminApiPath()}/task/withAttachment/${taskId}` : `${getAdminApiPath()}/task/withAttachment`;
  return await axios.post(url, formData).then(({data}) => data);
};
