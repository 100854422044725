import {GetLinkProp} from "@hornet-api/task/attachFile";
import getUserApiPath from "@hornet-api/getUserApiPath";
import {Attachment, ITask, Message, Task} from "@interfaces/task";
import axios from "axios";
import {SendMessageOption} from "@hornet-api/task/sendAdminMessage";
import {Announcements, GetMessageOptions} from "@hornet-api/task/getMessages";
import {getAccessToken} from "@common/token";
import {CreateTaskOptions} from "@hornet-api/task/updateTaskStatus";

type Props = {
  doc: Attachment;
  task: Task;
}

export const generateDocumentLinkUrl = ({doc, task}: Props) => {
  return getUserTaskAttachmentLinkUrl({
    taskId: task.id,
    attachmentId: doc.id,
    download: true,
  });
}

// TODO cleanup this function
export const getUserTaskAttachmentLinkUrl = (
  {
    taskId,
    attachmentId,
    download,
  }: GetLinkProp
) => {
  let url = getUserApiPath();
  const tail = download ? 'download' : 'view';
  return url + `/task/${taskId}/attachment/${attachmentId}/${tail}?access_token=${getAccessToken()}`;
}


export type AttachFilesProp = {
  files: File[] | null,
  taskId: number,
  isInternal?: boolean
}

export const attachUserTaskFile = async (
  {
    files,
    taskId,
    isInternal = false
  }: AttachFilesProp
) => {
  const url = getUserApiPath() + `/task/${taskId}/attachment`;
  const formData = new FormData();
  if (files && files.length > 0) {
    files.forEach((attachment) => {
      formData.append("taskAttachments", attachment);
    });
  }
  formData.append("isInternal", isInternal ? 'true' : 'false');

  const response = await axios.post<Task>(
    url,
    formData
  );
  return response.data;
};

export const notifyUserTyping = async (id: number) => {
  const url = getUserApiPath() + `/chat/${id}/notifyTyping`
  await axios.post(url)
}

export const sendUserMessage = async (messageBody: SendMessageOption) => {
  const baseUrl = getUserApiPath();
  const url = `${baseUrl}/chat`;
  return (messageBody.id ? axios.put : axios.post)(url, messageBody)
}


export const getMessagesForUser = async (
  {
    taskId,
    isAnnouncement = null,
    loanId
  }: GetMessageOptions
) => {
  let url = getUserApiPath();
  if (taskId) {
    url += `/task/${taskId}/chat/message`;
  } else {
    if (isAnnouncement) {
      url += '/chat/announcement/message';
    } else if (loanId) {
      url += `/loan/${encodeURIComponent(loanId)}/chat/message`;
    } else {
      url += `/contact/chat/message`;
    }
  }

  try {
    if (isAnnouncement) {
      const response = await axios.get<Announcements>(url);
      return (response.data[isAnnouncement] as Message[]).filter(x => !!x);
    }
    const response = await axios.get<Message[]>(url)
    return response.data.filter(x => !!x);
  } catch (e) {
    console.error('getMessagesForUser', e);
    return [];
  }

};

export const fetchContactOrEntityTasks = async () => {
  const url = `${getUserApiPath()}/task`
  const response = await axios.get<ITask[]>(url);
  return response.data;
};

export const updateUserTaskStatus = async ({
                                             taskId,
                                             taskStatus,
                                           }: CreateTaskOptions) => {
  const url = getUserApiPath() + `/task/${taskId}/status`;
  const data: any = {};
  if (taskStatus) data.taskStatus = taskStatus;
  const response = await axios.put(url, data);
  return response.data as Task;
};