import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Link, useParams} from 'react-router-dom';
import unsubscribe from "@hornet-api/public/unsbscribe";
import {alertApiErrors} from "@common/errors";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {useRecoilValue} from "recoil";
import profileAtom from "@state/recoil/profileAtom";
import hornetCapitalLogo from "@images/hornet_logo_regular.svg";

const UnsubscribePage = () => {
  const {token} = useParams() as {
    token: string
  };
  const profile = useRecoilValue(profileAtom);
  const [fullName, setFullName] = useState<string>('');
  useEffect(() => {
    if (token) {
      const t = loadingTrigger();
      unsubscribe(token)
        .then(({fullName}) => setFullName(fullName))
        .catch((e) => {
          alertApiErrors(e);
          setFullName('');
        })
        .finally(() => loadingRelease(t));
    }
  }, [token]);
  return (<>
    {
      !profile && (
        <Row className="mt-5 justify-content-center">
          <Col xs="12" className='text-center'>
            <img width="200" src={hornetCapitalLogo} alt="Hornet Capital" className="logo"/>
          </Col>
        </Row>
      )
    }
    <Container className="mt-5 ">
      {
        fullName && (<Row>
          <Col>
            <h5 className='text-start'>Hello {fullName},<br/><br/>You have successfully unsubscribed and will no longer receive emails from us. If you'd like to re-subscribe, log in, go to Profile, and turn on Email Notifications.</h5>
          </Col>
        </Row>)
      }
      <Row className='mt-4'>
        <Col>
          <Link className="btn btn-primary" to="/">{profile ? 'Home' : 'Login'} </Link>
        </Col>
      </Row>
    </Container>
  </>)
}
export default UnsubscribePage;
