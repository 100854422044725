import axios from 'axios';
import {ITask, Task} from '@interfaces/task';
import getAdminApiPath from "@hornet-api/getAdminApiPath";

type TaskResponse = {
    contactTasks: Task[],
    entityTasks: Task[],
}

type TaskPaginateResponse = {
    data: ITask[],
    total: number,
    totalUnresolvedCount: number,
    page: number
}

const getContactTasks = async (contactId: number) => {
  let url = `${getAdminApiPath()}/contact/${contactId}/task`;
  const response = await axios.get(url);

    const res: TaskResponse = response.data as TaskResponse;

    if (!res.contactTasks) {
        res.contactTasks = [];
    }

    if (!res.entityTasks) {
        res.entityTasks = [];
    }

    return res;

};

export interface IGetTasksOptions {
    id?: string,
    contact?: string,
    entity?: string,
    page?: number
}

export const getPaginateTasks = async (
  {
    id,
    contact,
    entity,
    page
  }: IGetTasksOptions
) => {
  const url = `${getAdminApiPath()}/task`;
  const response = await axios.post<TaskPaginateResponse>(
    url,
    {
      filters: {
        id,
        contact,
        entity,
        isNavData: true
      },
      paging: {
        page,
        pageSize: 20
      }
    });
  return response.data;
};

export default getContactTasks;
