import React, {useEffect, useState} from "react";
import {FaEdit, FaRegEye, FaTrash} from "react-icons/fa";
import RenameAttachment from "@admin-ui/pages/LoanShowPage/components/RenameAttachment";
import {Attachment, Task} from "@interfaces/task";
import {getLinkUrl} from "@hornet-api/task/attachFile";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {deleteTaskAttachments} from "@hornet-api/task/attachments";
import {refreshTaskDetails} from "@components/NotificationSidebar/utils";
import {alertApiErrors} from "@common/errors";
import {openShowDocumentModal} from "@components/ShowDocumentModal";
import {viewAttachmentUrl} from "@hornet-api/documents/viewAttachmentUrl";

type Props = {
  doc: Attachment;
  task: Task;
  isSidebarOpen: boolean;
}

const TaskAttachment = ({doc, task, isSidebarOpen}: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!isSidebarOpen) {
      setIsEditing(false);
    }
  }, [isSidebarOpen]);

  const generateDocumentLinkUrl = (doc: Attachment) => {
    return getLinkUrl({
      taskId: task.id,
      attachmentId: doc.id,
      download: true,
    });
  }

  const deleteTaskFile = (docId: number) => {
    const t = loadingTrigger();
    deleteTaskAttachments(task.id, docId)
      .then(() => refreshTaskDetails(true))
      .catch(alertApiErrors)
      .finally(() => loadingRelease(t));
  };

  const handleRename = () => {
    setIsEditing(false);
    refreshTaskDetails(true).then();
  }

  return (
    <div>
      {isEditing ? (
        <RenameAttachment
          taskId={task.id}
          fileId={doc.id}
          filename={doc.fileName}
          onComplete={handleRename}
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <a href={generateDocumentLinkUrl(doc)} target="_blank" rel="noopener noreferrer">
          {doc.fileName}
        </a>
      )}
      <>
        {!isEditing && (
          <span role='button' className='ml-2' onClick={() => setIsEditing(true)}>
              <FaEdit/>
            </span>
        )}
        <span
          role='button'
          className='ml-2'
          onClick={() => {
            if (confirm('Are you sure?')) deleteTaskFile(doc.id);
          }}
        >
            <FaTrash/>
          </span>
        <span
          role='button'
          className='ml-2'
          onClick={() => {
            openShowDocumentModal({
              url: viewAttachmentUrl(doc!.id),
              name: doc!.fileName
            })
          }}

        >
            <FaRegEye/>
          </span>
      </>
    </div>
  );
}

export default TaskAttachment;
