import React, {useMemo, useState} from 'react';
import TaskItem from './components/TaskItem';
import {FaCircleNotch, FaPlus} from 'react-icons/fa';
import InfiniteScroll from "react-infinite-scroll-component";
import {
  companyListState,
  isAddingTaskFromSidebarState,
  selectedTaskState,
  selectedTopMenuItemState,
  userListState
} from './globalState';
import style from './NotificationTasks.module.scss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {getUserCompanySelectGroups, IUserOrCompanyOption, triggerTaskRefresh} from './utils';
import Empty from './components/Empty';
import TaskDetail from './components/TaskDetail';
import BaseSelectBox from "@components/forms/BaseSelectBox";
import {addTaskModalState} from "@admin-ui/pages/LoanShowPage/components/AddTaskToLoanModal";
import {useTaskPagination} from "@components/NotificationSidebar/useTaskPagination";


const NotificationTasks = () => {
  const selectedTopMenuItem = selectedTopMenuItemState.useValue();
  const [selectedTask, setSelectedTask] = selectedTaskState.use();
  const userList = userListState.useValue();
  const companyList = companyListState.useValue();

  const [selectedUserOrCompany, setSelectedUserOrCompany] = useState<null | IUserOrCompanyOption>(null);
  const {tasks, tasksMetaData, loadNextPage} = useTaskPagination(selectedUserOrCompany);

  const showNotifications = useMemo(() => {
    return selectedTopMenuItem === 'tasks';
  }, [selectedTopMenuItem])



  const selectOptions = useMemo(() => {
    return getUserCompanySelectGroups()
  }, [userList, companyList]);

  if (!showNotifications) {
    return null;
  }

  // show task list
  if (selectedTask === null) {
    return (
      <div className={style.taskList}>
        {
          <div className={style.adminHeader}>
            <div className={style.selectBox}>
              <BaseSelectBox
                value={selectedUserOrCompany}
                options={selectOptions}
                isClearable={true}
                onChange={(e: null | { value: string, label: string }) => {
                  setSelectedUserOrCompany(e);
                  triggerTaskRefresh();
                }}
              />
            </div>
            <div className={style.tools}>
              <OverlayTrigger
                placement="bottom-end"
                overlay={<Tooltip id={`tt-add-chat`}>Add Task</Tooltip>}
              >
                <button
                  className='btn btn-primary'
                  name='Add Task'
                  onClick={() => {
                    isAddingTaskFromSidebarState.set(true);
                    addTaskModalState.set({onClose: () => isAddingTaskFromSidebarState.set(false)});
                  }}
                >
                  <FaPlus/>
                </button>
              </OverlayTrigger>
            </div>
          </div>
        }
        {
          tasks && tasks.length > 0 ?
            (
              <InfiniteScroll
                scrollableTarget={'scrollableNotificationSidebar'}
                dataLength={tasks.length}
                next={loadNextPage}
                hasMore={tasks.length < tasksMetaData.total}
                loader={<div className="text-center overflow-hidden"><FaCircleNotch className='spin'/></div>}
                endMessage={
                  <p className="text-center my-2">
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                {
                  tasks.map((task, index) => {
                    if (task.taskStatus === 'HIDDEN') {
                      return null;
                    }
                    return (<TaskItem task={task} key={index} onClick={() => {
                      setSelectedTask(task.id)
                    }}/>);
                  })
                }
              </InfiniteScroll>
            )
            :
            <Empty type='task'/>
        }
      </div>
    );
  }

  return <TaskDetail />;
}

export default NotificationTasks;
