import axios from 'axios';
import getPublicApiPath from "@hornet-api/getPublicApiPath";

export type UnsubscribeResponse = {
  fullName: string
}
const unsubscribe = async (token: string) => {
  const url = `${getPublicApiPath()}/handle-unsubscribe-link`;
  return axios.post<UnsubscribeResponse>(url, {token}).then(res => res.data);
};

export default unsubscribe;
