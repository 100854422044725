import React from 'react';
import {Column} from '@components/BootstrapSmartTable';
import BootstrapSmartTable from '@admin/components/BootstrapSmartTable';
import AmbassadorAdmin from '@interfaces/AmbassadorAdmin';
import {Card} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {currency} from "@common/basic";

type Props = {
  ambassadors: AmbassadorAdmin[];
  loading: boolean;
};

const AmbassadorsTable = ({ambassadors, loading}: Props) => {
  const columns: Column<AmbassadorAdmin>[] = [
    {
      id: 'name',
      title: 'Name',
      field: 'name',
      filter: true,
      render: (row) => <Link to={`/contact/show/${row.id}/ambassador`}>{row.name}</Link>,
    },
    {
      id: 'referralCount',
      title: 'Referrals',
      field: 'referralCount',
      filter: true,
      render: (row) => <>{row.referralCount}</>,
    },
    {
      id: 'amountPaid',
      title: 'Amount Paid',
      field: 'amountPaid',
      filter: true,
      render: (row) => currency(row.amountPaid),
    },
    {
      id: 'amountOwed',
      title: 'Amount Owed',
      field: 'amountOwed',
      filter: true,
      render: (row) => currency(row.amountOwed),
    },
    {
      id: 'amountDue',
      title: 'Amount Due',
      field: 'amountDue',
      filter: true,
      render: (row) => currency(row.amountDue),
    },
  ];

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>Ambassadors</Card.Title>
        </Card.Header>
        <Card.Body>
          <BootstrapSmartTable
            name="ambassadors"
            columns={columns}
            data={ambassadors}
            defaultFilters={[['taskStatus', 'PENDING']]}
            showFilter
            isLoading={loading}
            defaultSortKey={['taskStatus', 'descending']}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default AmbassadorsTable;
