import axios from 'axios';
import {Message} from '@interfaces/task';
import getAdminApiPath from "@hornet-api/getAdminApiPath";

export type GetMessageOptions = {
  taskId?: number,
  contactId?: number,
  companyId?: number,
  contactCompanyId?: number,
  isAnnouncement?: null | 'investor' | 'borrower'
  loanId?: string,
}
export type Announcements = {
  investor: Message[]
  borrower: Message[]
}

const getMessages = async (
  {
    taskId,
    contactId,
    isAnnouncement = null,
    loanId
  }: GetMessageOptions
) => {
  // TODO see if we can combine these APIs together
  let url = getAdminApiPath();
  if (taskId) {
    url += `/task/${taskId}/chat/message`;
  } else {
    // generic chat
    if (isAnnouncement) {
      url += '/chat/announcement/message';
    } else if (contactId) {
      url += `/contact/${contactId}/chat/message`;
    } else if (loanId) {
      url += `/loanAdmin/${encodeURIComponent(loanId)}/chat/message`;
    }
  }

  const response = await axios.get(url);
  try {
    if (isAnnouncement) {
      const response = await axios.get<Announcements>(url);
      return (response.data[isAnnouncement] as Message[]).filter(x => !!x);
    }
    const response = await axios.get<Message[]>(url)
    return response.data.filter(x => !!x);
  } catch(e) {
    console.error('getMessages', e);
    console.error('getMessages: data', response.data);
    return [];
  }

};

export default getMessages;
