import {useCallback, useEffect} from "react";
import {tasksMetaState, tasksState} from "@components/NotificationSidebar/globalState";
import {getPaginateTasks} from "@hornet-api/task/getTasks";
import {IUserOrCompanyOption} from "./utils";

export const useTaskPagination = (selectedUserOrCompany: IUserOrCompanyOption | null) => {
  const tasks = tasksState.useValue();
  const [tasksMetaData, setMetaData] = tasksMetaState.use();

  const loadNextPage = useCallback(() => {
    setMetaData({...tasksMetaData, page: tasksMetaData.page + 1});
  }, [tasksMetaData]);

  useEffect(() => {
    if (tasksMetaData.page === -1) {
      return;
    }
    if (tasksMetaData.page === 0) {
      tasksState.set([]);
      loadNextPage();
      return;
    }

    const [type, idString] = selectedUserOrCompany?.value.split('-') || [];
    const data = {
      contact: type === 'CONTACT' ? `=${idString}` : undefined,
      entity: type === 'ENTITY' ? `=${idString}` : undefined,
      page: tasksMetaData.page
    };

    getPaginateTasks(data)
      .then(({data, ...taskMeta}) => {
        if (tasksMetaData.page === 1) {
          tasksState.set(data);
        } else {
          tasksState.set([...(tasks || []), ...data]);
        }

        setMetaData({...taskMeta, page: tasksMetaData.page})
      })
  }, [tasksMetaData.page]);

  return {tasks, tasksMetaData, loadNextPage};
};
