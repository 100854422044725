import axios from 'axios';
import {Task} from '@interfaces/task';
import getAdminApiPath from "@hornet-api/getAdminApiPath";
import isAdminState from "@state/globalState/isAdminState";
import getUserApiPath from "@hornet-api/getUserApiPath";
import {getAccessToken} from "@common/token";

export type AttachFilesProp = {
  files: File[] | null,
  taskId: number,
  isInternal?: boolean
}
const attachFile = async (
  {
    files,
    taskId,
    isInternal = false
  }: AttachFilesProp
) => {
  const url = getAdminApiPath() + `/task/${taskId}/attachment`;
  const formData = new FormData();
  if (files && files.length > 0) {
    files.forEach((attachment) => {
      formData.append("taskAttachments", attachment);
    });
  }
  formData.append("isInternal", isInternal ? 'true' : 'false');

  const response = await axios.post(
    url,
    formData
  );
  return response.data as Task;
};

export type GetLinkProp = {
  taskId: number,
  attachmentId: number,
  download?: boolean,
};

export const getLinkUrl = (
  {
    taskId,
    attachmentId,
    download,
  }: GetLinkProp
) => {
  const baseUrl = isAdminState.get() ? getAdminApiPath() : getUserApiPath();
  const action = download ? `download?access_token=${getAccessToken()}` : 'view';
  return `${baseUrl}/task/${taskId}/attachment/${attachmentId}/${action}`;
}


export default attachFile;
