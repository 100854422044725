import React, {useEffect, useMemo, useState} from 'react';
import {notificationSidebarIsOpenState, selectedTaskDetailState, selectedTaskState} from '../globalState';
import {deleteChat, getTaskOwnerName, refreshTaskDetails, setIsLoading, triggerTaskRefresh} from '../utils';

import {FaChevronLeft, FaCircleNotch, FaPlus, FaTrash} from 'react-icons/fa';
import classNames from 'classnames';
import style from '../NotificationTasks.module.scss';
import Messages from './Messages';
import MessageInput from './MessageInput';
import {addFileModalState} from './AddFileModal';
import {TaskStatusEnum} from '@interfaces/GeneratedEnums';
import {updateTaskStatus} from '@hornet-api/task/updateTaskStatus';
import getMessages, {GetMessageOptions} from '@hornet-api/task/getMessages';
import attachFile, {getLinkUrl} from '@hornet-api/task/attachFile';
import {Attachment, ChatMessage, Message} from '@interfaces/task';
import {Link} from "react-router-dom";
import ReactQuill from "react-quill";
import _ from "lodash";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import TaskAttachment from "@components/NotificationSidebar/components/TaskAttachment";
import {alertApiErrors} from "@common/errors";
import ChatFileDropZone from "@components/NotificationSidebar/components/ChatFileDropZone";
import FileDropZone from "@components/NotificationSidebar/components/FileDropZone";
import {addAlert} from "@components/Alert";
import {pluralize} from "@common/utils/stringUtil";
import deleteTaskAdmin from "@hornet-api/task/admin/deleteTaskAdmin";
import {attachUserTaskFile} from "@hornet-api/task/user";
import isAdminState from "@state/globalState/isAdminState";


const TaskDetail = () => {
  const [task, setTask] = selectedTaskDetailState.use();
  const isSidebarOpen = notificationSidebarIsOpenState.useValue();

  const [messageToEdit, setMessageToEdit] = useState(null as null | ChatMessage)
  const [isDeleting, setIsDeleting] = useState(false);
  const [messages, setMessages] = useState(null as null | Message[]);
  const selectedTask = selectedTaskState.useValue();
  const isAdmin = isAdminState.useValue();


  const generateDocumentLinkUrl = (doc: Attachment) => {
    return getLinkUrl({
      taskId: task?.id!,
      attachmentId: doc.id,
      download: true
    })
  }

  useEffect(() => {
    if (isSidebarOpen && selectedTask) {
      refreshTaskDetails(true).then();
    }
  }, [isSidebarOpen, selectedTask])

  const updateMessages = _.debounce(async () => {
    if (!task || !task.chat) return;
    const t = loadingTrigger();
    try {
      let opts: GetMessageOptions = {
        taskId: task.id,
      }
      if ('contact' in task) opts.contactId = task.contact;
      const m = await getMessages(opts);
      setMessages(m);
    } catch (e) {
      console.error('Error loading task messages', e);
    }
    loadingRelease(t);
  }, 100);

  const deleteThisTask = async () => {
    if (!task || isDeleting) return;
    setIsDeleting(true);

    try {
      await deleteTaskAdmin(task.id);
      setIsDeleting(false);
      setTask(null);
      selectedTaskState.set(null);
      triggerTaskRefresh();
    } catch (e) {
      setIsDeleting(false);
      alert('Something went wrong');
    }
  }

  const messagesMemo = useMemo(() => messages || [], [messages]);

  useEffect(() => {
    if (task) {
      updateMessages();
    }
  }, [task]);

  const description = useMemo(() => {
    if (!task) return null;

    return (
      <ReactQuill
        modules={{toolbar: false}}
        value={task.description}
        readOnly
        className={'show-only mb-2'}
      />
    )
  }, [task])

  const loanLink = useMemo(() => {
    if (!task || !('loanId' in task)) return null;
    const url = `/loan/show/${task?.loanId}`
    return (
      <Link
        to={url}
        onClick={() => {
          notificationSidebarIsOpenState.set(false);
        }}
      >
        {task.loanId}
      </Link>
    );
  }, [task]);

  if (!task) {
    return null;
  }
  const ownerName = getTaskOwnerName(task);
  // show the task

  const onDropTaskAttachment = (files: File[]) => {
    setIsLoading(true);
    (isAdmin ? attachFile : attachUserTaskFile)({
      files: files,
      taskId: task.id,
    })
      .then(() => {
        addAlert({
          type: 'success',
          content: `${pluralize('File', files.length)} uploaded successfully.`
        })
        return refreshTaskDetails(true)
      })
      .catch(alertApiErrors)
      .finally(() => {
        setIsLoading(false)
      });
  }

  return (
    <div className={classNames(style.isAdmin, style.task)}>
      <div className={style.top}>
        <div
          className={style.left}
          onClick={() => {
            selectedTaskState.set(null);
            triggerTaskRefresh();
          }}
        >
          <FaChevronLeft/> BACK
        </div>
        <div className={classNames(style.right, style.isAdmin)}>
          {
            <>
              <div className={style.selectBox}>
                <select
                  className="form-control"
                  value={task.taskStatus}
                  onChange={async (e) => {
                    const data: any = {
                      taskId: task.id,
                      taskStatus: e.target.value
                    };
                    updateTaskStatus(data).then(setTask);
                  }}
                >
                  {
                    Object.keys(TaskStatusEnum).map((key, index) => {
                      return (
                        <option value={key} key={index}>
                          {/* @ts-ignore */}
                          {TaskStatusEnum[key]}
                        </option>
                      );
                    })
                  }
                </select>
              </div>
              <div
                className={style.menuBtn}
                onClick={() => {
                  if (confirm("Are you sure you want to delete this task? This cannot be undone.")) {
                    deleteThisTask().then();

                  }
                }}
              >
                {isDeleting ? <FaCircleNotch className={'spin'}/> : <FaTrash/>}
              </div>
            </>
          }
        </div>
      </div>
      <div style={{position: 'relative'}}>
        <FileDropZone
          onDropFiles={onDropTaskAttachment}
          message={'Drop the task related files here ...'}
        />
        <div className={style.descriptionBox}>
          {
            <div className={style.ownerName}>
              {ownerName}
            </div>
          }
          <div className={style.title}>
            {task.title}
          </div>
          <div className={style.content}>
            {description}
          </div>
          {
            task.loanId ?
              <div className={style.loanId}>
                LOAN: {loanLink}
              </div>
              :
              null
          }
          <hr/>
        </div>
        <div className={style.subBox}>
          <div className={style.subTop}>
            <div className={style.left}>
              <div className={style.subTitle}>
                Attachments
              </div>
            </div>
            <div
              className={style.addFile}
              onClick={() => {
                addFileModalState.set({
                  files: null,
                  taskId: task.id,
                  onClose: refreshTaskDetails
                })
              }}
            >
              <FaPlus/> ADD FILES
            </div>
          </div>
          {
            task.templateAttachments.map((doc, index) => {
              const url = generateDocumentLinkUrl(doc)
              return (
                <div key={index}><a href={url} target="_blank">{doc.fileName}</a></div>
              );
            })
          }
          {
            task.attachments.map((doc, index) => (
              <TaskAttachment doc={doc} key={index} task={task} isSidebarOpen={isSidebarOpen}/>))
          }
        </div>

      </div>
      <hr/>
      {
        task.chat && (
          <div style={{position: 'relative', flexGrow: 1, minHeight: '450px'}}>
            <Messages
              task={task}
              isResolved={task.chat.isResolved}
              messages={messagesMemo}
              onSubmitted={async () => {
                triggerTaskRefresh();
                await Promise.all([refreshTaskDetails(), updateMessages()]);
              }}
              messageToEdit={messageToEdit}
              onEdit={(message) => setMessageToEdit(message)}
              onDelete={(message) =>
                message &&
                deleteChat(message.id)
                  .then(() => refreshTaskDetails(true))
                  .catch(alertApiErrors)
              }
              onDeleteChatAttachment={refreshTaskDetails}
            />
            <MessageInput
              chat={task.chat}
              messageToEdit={messageToEdit}
              onSubmitted={async () => {
                setMessageToEdit(null);
                triggerTaskRefresh();
                await Promise.all([refreshTaskDetails(), updateMessages()]);
              }}
            />
            {<ChatFileDropZone chatId={task.chat.id} onClose={refreshTaskDetails}/>}
          </div>
        )
      }
    </div>
  );
}

export default TaskDetail;
