import axios from 'axios';
import {Message} from '@interfaces/task';
import getAdminApiPath from "@hornet-api/getAdminApiPath";

export type MarkResolvedMessage = {
  taskId?: number,
  chatId?: number
}
const markChatResolved = async (
  {
    taskId,
    chatId,
  }: MarkResolvedMessage
) => {
  let url = getAdminApiPath();
  if (taskId) {
    url += `/task/${taskId}/chat/resolve`;
  } else if (chatId) {
    // normal chat
    url += `/chat/${chatId}/resolve`;
  } else {
    alert('Not supported');
    return;
  }

  const response = await axios.put(url, {});
  return response.data as Message[];
};

export default markChatResolved;