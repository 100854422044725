import React from "react";
import {Column} from "@admin/components/BootstrapSmartTable";
import {Button, Tooltip, OverlayTrigger} from "react-bootstrap";
import FontAwesomeIcon from "@admin/components/icons/FontAwesomeIcon";

import {formatDate} from "@common/basic";
import {alertApiErrors} from "@common/errors";
import {deleteFirebaseMobileDevice} from "@hornet-api/twoFactorAuth/user/mobile/deleteFirebaseMobileDevice";
import {DateFormats} from "@interfaces/DateFormats";

interface FireBaseTwoFactorDevice {
  id: number;
  firebaseDeviceName: string;
  firebaseDeviceOs: string;
  dateCreated: string;
  lastUsed: string;
}

export interface RegisteredTrustedDevices {
  twoFaMobileDevices: FireBaseTwoFactorDevice[];
}

export const columnsFireBaseTwoFactorDevice = (reloadData: () => void) => {
  const columns: Column<FireBaseTwoFactorDevice>[] = [
    {
      id: 'frDevicNm',
      title: 'Device Name',
      field: 'firebaseDeviceName'
    },
    {
      id: 'frDevicOs',
      title: 'OS',
      field: 'firebaseDeviceOs'
    },
    {
      id: 'frLast',
      title: 'Last Used',
      field: 'lastUsed',
      render: (rowData) => formatDate(rowData.lastUsed, DateFormats.SEMI)
    },
    {
      id: 'creDate',
      title: 'Created Date',
      field: 'dateCreated',
      render: (rowData) => formatDate(rowData.dateCreated, DateFormats.SEMI)
    },
    {
      id: 'action',
      title: 'Actions',
      field: 'actions',
      render: (rowData) => <OverlayTrigger
        placement="bottom-end"
        overlay={<Tooltip id={`tt-delete-${rowData.id}`}>Delete</Tooltip>}>
        <Button
          variant={'danger'}
          size={'sm'}
          onClick={() => {
            if (confirm('Are your sure?')) {
              deleteFirebaseMobileDevice(rowData.id)
                .then(reloadData)
                .catch(alertApiErrors)
            }
          }}
        >
          <FontAwesomeIcon icon="trash"/>
        </Button>
      </OverlayTrigger>
    }
  ];
  return columns;
}
