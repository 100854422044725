import axios from 'axios';
import {Task} from '@interfaces/task';
import {TaskStatusEnum,} from '@interfaces/GeneratedEnums';
import getAdminApiPath from "@hornet-api/getAdminApiPath";

export type CreateTaskOptions = {
  taskId: number,
  taskStatus?: keyof typeof TaskStatusEnum
}

export const updateTaskStatus = async (
  {
    taskId,
    taskStatus,
  }: CreateTaskOptions) => {
  const url = getAdminApiPath() + `/task/${taskId}/status`;
  const data = taskStatus ? {taskStatus} : {};
  const response = await axios.put(url, data);
  return response.data as Task;
};


