import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {createGlobalState} from 'react-global-hooks';
import FontAwesomeIcon from '@components/Icons/FontAwesomeIcon';
import attachFile, {AttachFilesProp} from '@hornet-api/task/attachFile';
import {FileUploadControl} from "@components/forms/react-hook-form-bootstrap";

import {Form} from "react-bootstrap";
import {SubmitHandler, useForm} from "react-hook-form";
import {addAlert} from "@components/Alert";
import isAdminState from "@state/globalState/isAdminState";
import {attachUserTaskFile} from "@hornet-api/task/user";

export interface AttachFilesPropObj extends AttachFilesProp {
  onClose?: () => void
}

export const addFileModalState = createGlobalState(null as AttachFilesPropObj | null);


const AddFileModal = () => {
  const [addFileState, setAddFileState] = addFileModalState.use();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [error, setError] = useState(null as null | string);
  const isAdmin = isAdminState.useValue();

  const {
    reset,
    control,
    handleSubmit,
  } = useForm<AttachFilesPropObj>();

  const handleClose = () => {
    if (addFileState && addFileState.onClose) {
      addFileState.onClose();
    }
    setAddFileState(null);
    setIsUploading(false);
    setError(null);
    reset();
  };
  
  const onSubmit: SubmitHandler<AttachFilesPropObj> = async (data) => {
    if (addFileState && !isUploading) {
      setError(null);
      setIsUploading(true);
      (isAdmin ? attachFile : attachUserTaskFile)({
        ...addFileState,
        files: data.files
      }).then(() => {
        addAlert({
          type: 'success',
          content: `File${data!.files!.length > 1 ? 's' : ''} uploaded successfully.`
        })
        handleClose();
      }).catch(e => {
        setIsUploading(false);
        setError('Something went wrong');
        console.error(e);
      })
    }
  }

  return (
    <Modal
      show={addFileState !== null}
      onHide={handleClose}
      id={'attach-files-task-modal'}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Attach Files
        </Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          {
            error ?
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
              :
              null
          }
          <FileUploadControl
            name={'files'}
            label={'Attachments'}
            control={control}
            multiple={true}
            rules={{required: true}}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
          >
            {
              isUploading ?
                <FontAwesomeIcon icon='circle-o-notch' spin={true}/>
                :
                'Upload'
            }
          </Button>
          <Button
            variant="secondary"
            onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddFileModal;
