import React, {useEffect} from 'react';
import {createGlobalState} from 'react-global-hooks';
import {Button, Form, Modal} from "react-bootstrap";
import {SubmitHandler, useForm} from "react-hook-form";
import {TextBox} from "@components/forms/react-hook-form-bootstrap";
import {defaultSubmitFeedback, SubmitFeedbackForm, SubmitFeedbackServer} from "@interfaces/feedback/Feedback";
import {useRecoilValue} from "recoil";
import {saveUserFeedback} from "@hornet-api/feedback";
import {alertApiErrors} from "@common/errors";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import authenticationBlobAtom from "@state/recoil/authentication/core/authenticationBlobAtom";

interface SubmitFeedbackModalState {
  page: string;
  subPage?: string;
  onModalCancel?: () => void;
  onFeedbackSubmit?: () => void;
}

const submitFeedbackModalState = createGlobalState<SubmitFeedbackModalState | null>(null);

export const openSubmitFeedbackModal = (modalState: SubmitFeedbackModalState) => {
  submitFeedbackModalState.set({...modalState});
};

const SubmitFeedBackModal: React.FC = () => {
  const authentication = useRecoilValue(authenticationBlobAtom);
  const [modalState, setModalState] = submitFeedbackModalState.use();
  const {control, handleSubmit, reset} = useForm<SubmitFeedbackForm>();

  useEffect(() => {
    reset(defaultSubmitFeedback);
  }, [modalState, reset]);

  const onCancel = () => {
    modalState?.onModalCancel?.();
    onClose();
  };

  const onClose = () => setModalState(null);

  const onSubmit: SubmitHandler<SubmitFeedbackForm> = async (data) => {
    if (!data || !modalState) return;
    const adminUser = authentication.impersonation_user?.adminUser || undefined
    const t = loadingTrigger();
    const serverData: SubmitFeedbackServer = {...data, ...modalState, adminUser}
    saveUserFeedback(serverData).then(() => {
      reset(defaultSubmitFeedback);
      modalState?.onFeedbackSubmit?.();
      onClose();
    })
      .catch(alertApiErrors)
      .finally(() => loadingRelease(t));
  }

  return (
    <Modal
      show={!!modalState}
      onHide={onCancel}
      id="submitFeedBackModal"
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title>Submit Feedback</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <TextBox
            name="comment"
            label="Feedback Comment"
            type="string"
            as="textarea"
            rows={4}
            control={control}
            rules={{
              required: "Feedback comment is required",
              maxLength: {value: 1000, message: "Max length is 1000 characters"},
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel}>
            Close
          </Button>
          <Button type="submit" variant="primary">
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default SubmitFeedBackModal;
